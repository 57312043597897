<template>
    <div class="DispositionRightBox" v-if="item_info">
        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Recipient</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a v-if="recipient_type" :href="'/' + item_type + '/' + recipient_type + '/' + item_info.recipient_id + '/view'">{{ item_info.recipient_name }}</a>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Pro Rata Per Unit</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="item_info.value && item_info.currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, item_info.value)">{{getCurrencyByValue(item_info.currency).icon + item_info.value}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Pro Rata Total</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="item_info.total_value && item_info.currency" v-b-tooltip:hover.bottom :title="toUserCurrencyValue(item_info.currency, item_info.total_value)">{{getCurrencyByValue(item_info.currency).icon + item_info.total_value}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Ref</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="reference">{{reference}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="common_col_bg_box">
            <div class="row">

                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Date</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="created_at">{{ created_at | moment("MMMM Do YYYY") }}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Source</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="source !== null">{{sources_list[source]}}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Source ID</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="source_info">
                                    <a :href="'/' + item_type + '/'+source_info.item_type+'/'+source_info.id+'/view'">{{source_info.name}}</a>
                                </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="cm_usage border_r">
                        <span>Created By</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="asset_user_info">
                                    <a :href="'/' + item_type + '/users/'+asset_user_info.id+'/view'">{{asset_user_info.name}}</a>
                                </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div v-else class="DispositionRightBox">
        <div class="common_col_bg_box" v-if="character_info">
            <div class="row">
                <div class="col-md-12">
                    <a :href="'/' + item_type + '/characters/'+character_info.id+'/view'" class="badge text-af-primary af-soft-accent font-size-11 m-1">({{ character_info.character_number || '' }}) {{ character_info.name }}</a>
                </div>
            </div>
        </div>
        <div class="common_col_bg_box">
            <div class="row">
                <div class="col-md-6">
                    <div class="cm_usage border_r">
                        <span>Plan</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <a href="#" @click="performAction(my_asset, 'add_fdp_info', $event)"><span>{{ getSustainabilityActionFor(my_asset, true) }}</span></a>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cm_usage border_r">
                        <span>Status</span>
                        <p>
                            <span>
                                <i class="font-size-18 bx bxs-circle status-icon" :class="my_asset.fdp_status === 1 ? 'status-green confirmed-fdp-action' : (my_asset.fdp_status === 0 ? 'status-red waiting-fdp-action' : 'status-orange proposed-fdp-action')"></i>
                                {{ my_asset.fdp_status === 1 ? 'Approved' : (my_asset.fdp_status === 0 ? 'Waiting' : 'Proposed') }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="cm_usage border_r">
                        <span>Updated At</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="my_asset.hasOwnProperty('fdp_updated_at') && my_asset.fdp_updated_at">{{ my_asset.fdp_updated_at | moment('MMMM Do YYYY') }}</span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="cm_usage border_r">
                        <span>Updated By</span>
                        <p>
                            <i class="bx bx-detail"></i>
                            <span v-if="my_asset.hasOwnProperty('fdp_updated_by')">
                                <a v-if="my_asset.fdp_updated_by !== 0 && my_asset.hasOwnProperty('fdp_updated_by_user') && my_asset.fdp_updated_by_user"
                                   :href="'/' + item_type + '/users/'+my_asset.fdp_updated_by+'/view'"
                                   class="text-dark">{{ my_asset.fdp_updated_by_user.name | titleize}}</a>
                                <span v-else>AF Intelligence</span>
                            </span>
                            <span v-else>-</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>



        <div class="common_col_bg_box" v-if="my_asset.hasOwnProperty('fdp_proposal_info') && my_asset.fdp_proposal_info && my_asset.fdp_status === 1">
            <div class="row">
                <div class="col-md-12">
                    <button v-if="hasPermission('action_fdp')" class="btn btn-primary btn-sm fdp-btn fdp-btn-register"
                            @click="addDispositionFields($event)">{{ show_more_dispo_details ? 'Cancel' : 'Register Disposition' }}</button>

                    <button v-if="hasPermission('approve_fdp_proposal')" class="btn btn-primary btn-sm fdp-btn fdp-btn-unapprove" @click="unApproveFDP($event)">Unaprove</button>
                </div>
            </div>
        </div>

        <div class="common_col_bg_box" v-if="my_asset.hasOwnProperty('fdp_proposal_info') && my_asset.fdp_proposal_info && my_asset.fdp_status !== 1">
            <div class="row">
                <div class="col-xl-12">
                    <button class="btn btn-primary btn-sm fdp-btn fdp-btn-change" @click="shallChangeFDP($event)">Change</button>
                    <button class="btn btn-primary btn-sm fdp-btn fdp-btn-clear" @click="shallClearFDP($event);">Clear</button>
                </div>
            </div>
        </div>

        <div class="common_col_bg_box" v-if="Object.keys(required_fields).length > 0 && (my_asset.fdp_status !== 1 || show_more_dispo_details)">
            <div class="row">
                <div class="col-xl-12">
                    <div class="fdp-step-common-info-block step-common-info-block">
                        <div class="fdp-st--common--box st--common--box" v-for="(required_field, section_field) in required_fields" :key="section_field">
                            <div v-if="required_field.name" class="fdp-common-st-title-label common-st-title-label">
                                <label>{{ required_field.name }}</label>
                            </div>

                            <div class="fdp-st-radio-row st-radio-row" v-if="required_field.type === 'radio-select'">
                                <div v-for="(option, index) in required_field.options"
                                     :key="index"
                                     class="fdp-st-radio-col col-2" :class="required_field.custom_class">
                                    <div class="fdp-st-radio-btn cm-radio-btn" :class="'mt-1 ' + section_field + option.id">
                                        <label class="fdp-st-radio-label">
                                            <input
                                                class="fdp-st-radio-input"
                                                v-model="addForm[section_field]"
                                                :key="index"
                                                :id="section_field + '_' + index"
                                                :name="section_field"
                                                :value="option"
                                                @click="changedRadioValue(section_field, option, $event)"
                                                type="radio"/>
                                            <div class="fdp-st-radio-box st-radio-box" :class="(addForm[section_field] && option.id === addForm[section_field].id) ? 'force-checked' : ''">
                                                <p>{{ option.name| titleize }}</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="st-radio-row" v-if="['final_disposition_planning', 'disposition_type'].includes(section_field) && (show_more_fdp_details || show_more_dispo_details) && final_disposition_planning && final_disposition_planning.id !== 0">
                                <div v-if="final_disposition_planning.hasOwnProperty('ends_ownership')"
                                     class="col-xl-12 mt-1 mb-1">
                                    <span class="ends-ownership" v-if="final_disposition_planning.ends_ownership">Ownership for this item will be changed to 'Ended'</span>
                                    <span class="no-change-ownership" v-else>Ownership for this item won't be changed</span>
                                </div>

                                <div v-if="has_assignments" class="col-xl-12 mt-1 mb-1">
                                    <div class="step-small-label">Assignments</div>

                                    <handleDispositionField
                                        :my_asset="my_asset"
                                        :addForm="addForm"
                                        :final_disposition_planning="final_disposition_planning"
                                        field_label="Contact"
                                        field_name="contact_id"
                                        field_type="single-select"
                                        :received_field_options="disposition_field_options.contact_id"
                                        @set_field="setField"
                                    />

                                    <handleDispositionField
                                        :my_asset="my_asset"
                                        :addForm="addForm"
                                        :final_disposition_planning="final_disposition_planning"
                                        field_label="Department"
                                        field_name="department_id"
                                        field_type="single-select"
                                        :received_field_options="disposition_field_options.department_id"
                                        @set_field="setField"
                                    />

                                    <handleDispositionField
                                        :my_asset="my_asset"
                                        :addForm="addForm"
                                        :final_disposition_planning="final_disposition_planning"
                                        field_label="Address"
                                        field_name="address_id"
                                        field_type="single-select"
                                        :received_field_options="disposition_field_options.address_id"
                                        @update_locations_list="updateLocationsList"
                                        @set_field="setField"
                                    />
                                    <handleDispositionField
                                        ref="LocationDispositionField"
                                        :my_asset="my_asset"
                                        :addForm="addForm"
                                        :final_disposition_planning="final_disposition_planning"
                                        field_label="Location"
                                        field_name="location_id"
                                        field_type="single-select"
                                        :received_field_options="disposition_field_options.location_id"
                                        @set_field="setField"
                                    />

                                </div>

                                <handleDispositionField
                                    :addForm="addForm"
                                    :final_disposition_planning="final_disposition_planning"
                                    field_label="Disposition Date"
                                    field_name="has_date"
                                    field_type="date"
                                    @set_field="setField"
                                />

                                <handleDispositionField
                                    :addForm="addForm"
                                    :final_disposition_planning="final_disposition_planning"
                                    field_label="Reference"
                                    field_name="has_reference"
                                    field_type="text"
                                    @set_field="setField"
                                />

                                <handleDispositionField
                                    :addForm="addForm"
                                    :final_disposition_planning="final_disposition_planning"
                                    field_label="Sale Price"
                                    field_name="has_amount"
                                    field_type="number"
                                    :my_asset="my_asset"
                                    @set_field="setField"
                                />

                                <handleDispositionField
                                    :addForm="addForm"
                                    :final_disposition_planning="final_disposition_planning"
                                    field_label="Recipient Info"
                                    field_name="has_recipient"
                                    field_type="single-select"
                                    :received_field_options="disposition_field_options.recipient_id"
                                    @set_field="setField"
                                />

                                <handleDispositionField
                                    :addForm="addForm"
                                    :final_disposition_planning="final_disposition_planning"
                                    field_label="Movement Info"
                                    field_name="has_movement"
                                    field_type="textarea"
                                    @set_field="setField"
                                />

                                <handleDispositionField
                                    :addForm="addForm"
                                    :final_disposition_planning="final_disposition_planning"
                                    field_label="Disposition Details"
                                    field_name="disposition_details"
                                    field_type="textarea"
                                    @set_field="setField"
                                />
                            </div>

                            <div class="fdp-btns-block mt-3" v-if="my_asset.fdp_status !== 1 && section_field === 'final_disposition_planning' && final_disposition_planning">
                                <button
                                    v-if="final_disposition_planning.id !== 0"
                                    class="btn btn-primary btn-sm fdp-btn fdp-btn-propose" @click="final_disposition_planning.id !== 0 ? proposeFDP($event) : shallClearFDP($event)">Propose</button>
                                <button v-if="final_disposition_planning.id !== 0"
                                        class="btn btn-outline-primary btn-sm fdp-btn fdp-btn-show-details" @click="show_more_fdp_details = !show_more_fdp_details">{{ show_more_fdp_details ? 'Hide' : 'Show' }} Details</button>
                                <button v-if="my_asset.hasOwnProperty('fdp_proposal_info') && my_asset.fdp_proposal_info"
                                        class="btn btn-primary btn-sm fdp-btn fdp-btn-cancel" @click="cancelProposing($event)">Cancel</button>
                            </div>
                            <div class="fdp-btns-block mt-3" v-else-if="my_asset.fdp_status === 1">
                                <button class="btn btn-primary btn-sm fdp-btn fdp-btn-propose" @click="registerDisposition($event)">Register Disposition</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>

import AssetTabsShared from "@/mixins/AssetTabsShared.mixin";
import HandleDispositionField from "./HandleDispositionField";

export default {
    components: {HandleDispositionField},
    mixins: [AssetTabsShared],
    name: 'DispositionRightBoxNew',
    props: {
        my_asset: {},
        item_type: {},
        performAction: {},
        changedRadioValueOf: {}
    },
    data(){
        return {
            has_assignments:false,
            show_more_fdp_details:false,
            show_more_dispo_details:false,
            sources_list:[
                'Asset',
                'Actions',
                'Orders',
                'Storage Orders',
            ],
            addForm: {
                final_disposition_planning: null,
                disposition_type: null,
                fdp_status: null
            },
            disposition_field_options:{
                address_id: [],
                location_id: [],
                department_id: [],
                recipient_id: [],
                contact_id: [],
            },
            required_fields: {},
        }
    },
    computed:{
        character_info(){
            return this.getValueOrDefault(this.my_asset, 'character_info', null);
        },
        item_info(){
            return this.getValueOrDefault(this.my_asset, 'disposition_info', null);
        },
        recipient_type(){
            return this.getValueOrDefault(this.item_info, 'recipient_type', null);
        },
        reference(){
            return this.getValueOrDefault(this.item_info, 'reference', null);
        },
        created_at(){
            return this.getValueOrDefault(this.item_info, 'disposition_date', null);
        },
        source(){
            return this.getValueOrDefault(this.item_info, 'source', null);
        },
        source_info(){
            return this.getValueOrDefault(this.item_info, 'source_info', null);
        },
        asset_user_info(){
            return this.getValueOrDefault(this.item_info, 'user_info', null);
        },
        final_disposition_planning(){
            if (this.my_asset.fdp_status === 1){
                return this.addForm.disposition_type;
            }
            return this.addForm.final_disposition_planning;
        }
    },
    watch: {
        my_asset:{
            deep: true,
            handler: function (newValue) {
                this.setDefaultValues();
            }
        },
    },
    created() {
        this.addForm.asset_id = this.my_asset.id;

        this.addFDPFields();
        this.addFDPStatusField();
        this.setDefaultValues();

    },
    methods:{
        addDispositionFields(event) {
            if (event){
                this.show_more_dispo_details = !this.show_more_dispo_details;
                let asset_has_proposal_info = this.my_asset.hasOwnProperty('fdp_proposal_info') && this.my_asset.fdp_proposal_info;
                this.required_fields = {};
                if (asset_has_proposal_info && this.my_asset.fdp_status === 1){
                    this.required_fields.disposition_type = {
                        name: 'Disposition Type',
                        type: 'radio-select',
                        custom_class: 'fdp-field',
                        has_permission: true,
                        default_value: null,
                        options: []
                    };
                    this.appendFieldsAndSetDefaults('disposition_type');

                    if (this.required_fields.hasOwnProperty('disposition_type')){
                        let disposition_type = this.my_asset.final_disposition_planning ? this.my_asset.final_disposition_planning : 0;
                        let disposition_type_value = this.getObjectOf(disposition_type, this.required_fields.disposition_type.options);
                        if (disposition_type_value && disposition_type_value.id === 2){
                            // Long Term Storage
                            this.has_assignments = true;
                        }
                        this.$set(this.addForm, 'disposition_type', disposition_type_value);
                    }

                }
            }
        },
        appendFieldsAndSetDefaults(required_field_name) {
            let asset_has_proposal_info = this.my_asset.hasOwnProperty('fdp_proposal_info') && this.my_asset.fdp_proposal_info;
            let form_lists = this.$root.form_lists;
            if (form_lists) {
                this.required_fields[required_field_name].options = form_lists[required_field_name];

                this.disposition_field_options.address_id = form_lists.addresses_list;
                this.disposition_field_options.location_id = form_lists.locations_list;
                this.disposition_field_options.department_id = form_lists.departments_list;
                this.disposition_field_options.recipient_id = form_lists.recipients_list;
                this.disposition_field_options.contact_id = form_lists.contacts_list;

                if (asset_has_proposal_info) {

                    let fdp_proposal_info = this.my_asset.fdp_proposal_info;
                    let contact_id = fdp_proposal_info.contact_id;
                    if (form_lists.contacts_list) {
                        let foundIndex = form_lists.contacts_list.map(function (e) {
                            return e.user.id;
                        }).indexOf(contact_id);
                        if (foundIndex > -1) {
                            this.addForm.contact_id = form_lists.contacts_list[foundIndex];
                        }
                    }

                    this.addForm.department_id = this.getObjectOf(fdp_proposal_info.department_id, this.disposition_field_options.department_id);
                    this.addForm.address_id = this.getObjectOf(fdp_proposal_info.address_id, this.disposition_field_options.address_id);
                    this.addForm.location_id = this.getObjectOf(fdp_proposal_info.location_id, this.disposition_field_options.location_id);

                    let fdp_date = fdp_proposal_info.fdp_date;
                    this.addForm.has_date = fdp_date !== '' ? Vue.options.filters.moment(fdp_date, 'DD-MM-YYYY') : '';
                    this.addForm.has_amount = fdp_proposal_info.fdp_amount;
                    this.addForm.has_reference = fdp_proposal_info.fdp_reference;
                    this.addForm.has_movement = fdp_proposal_info.fdp_movement;
                    this.addForm.disposition_details = fdp_proposal_info.fdp_details;
                    this.addForm[required_field_name] = this.my_asset[required_field_name];

                    let fdp_recipient = fdp_proposal_info.fdp_recipient;
                    let fdp_recipient_type = fdp_proposal_info.fdp_recipient_type;
                    if (form_lists.recipients_list) {
                        let foundIndex = form_lists.recipients_list.map(function (e) {
                            return e.id + '-' + e.item_type;
                        }).indexOf(fdp_recipient + '-' + fdp_recipient_type);
                        if (foundIndex > -1) {
                            this.addForm.has_recipient = form_lists.recipients_list[foundIndex];
                        }
                    }
                    this.addForm = Object.assign({}, this.addForm);
                }
            }
        },
        addFDPFields() {
            this.required_fields = {};
            if (this.required_fields.hasOwnProperty('fdp_status')) {
                delete this.required_fields.fdp_status;
            }

            let propose_fdp_permission = this.hasPermission('propose_fdp');
            if (propose_fdp_permission){
                this.required_fields.final_disposition_planning = {
                    name: 'Propose Disposition',
                    type: 'radio-select',
                    custom_class: 'fdp-field',
                    has_permission: true,
                    default_value: {id: 0, name: 'Not Planned'},
                    options: []
                };

                this.appendFieldsAndSetDefaults('final_disposition_planning');
            }
        },
        addFDPStatusField() {
            let asset_has_proposal_info = this.my_asset.hasOwnProperty('fdp_proposal_info') && this.my_asset.fdp_proposal_info;
            let approve_fdp_proposal_permission = this.hasPermission('approve_fdp_proposal');

            if (approve_fdp_proposal_permission && asset_has_proposal_info) {
                if (asset_has_proposal_info && (this.required_fields.hasOwnProperty('final_disposition_planning') || this.required_fields.hasOwnProperty('disposition_type'))) {
                    this.required_fields = {};
                }

                let fdp_status = {
                    name: 'Approve Disposition',
                    type: 'radio-select',
                    custom_class: 'fdp-status-field',
                    has_permission: true,
                    default_value: {id: 0, name: 'No'},
                    options: [
                        {id: 1, name: 'Yes'},
                        {id: 0, name: 'No'}
                    ]
                };

                this.$set(this.required_fields, 'fdp_status', fdp_status);
            }
        },
        cancelProposing(event){
            if (event){
                this.addFDPStatusField();
                this.show_more_fdp_details = false;
            }
        },

        unApproveFDP(event){
            if (event){
                this.$set(this.addForm, 'fdp_status', { id: 0, name: 'No' });
                this.show_more_dispo_details = false;
                this.changedRadioValue('fdp_status', {id: 0}, event);
                this.addFDPStatusField();
            }
        },
        registerDisposition(event){
            let that = this;
            let next_action = function (my_asset) {
                that.required_fields = {};
            }
            this.performAction(this.addForm, 'registerDisposition', event, null, next_action);

        },
        proposeFDP(event){
            if (event){
                let that = this;
                let next_action = function (my_asset) {
                    if (my_asset.hasOwnProperty('fdp_proposal_info') && my_asset.fdp_proposal_info){
                        that.$set(that.my_asset, 'fdp_proposal_info', my_asset.fdp_proposal_info);
                    }

                    if (my_asset.hasOwnProperty('final_disposition_planning')){
                        that.$set(that.my_asset, 'final_disposition_planning', my_asset.final_disposition_planning);
                    }
                    if (my_asset.hasOwnProperty('disposition_type')){
                        that.$set(that.my_asset, 'disposition_type', my_asset.disposition_type);
                    }
                    if (my_asset.hasOwnProperty('fdp_status')){
                        that.$set(that.my_asset, 'fdp_status', my_asset.fdp_status);
                    }

                    that.addFDPStatusField();
                }

                this.performAction(this.addForm, 'proposeFDP', event, null, next_action);
            }
        },
        shallClearFDP(event){
            if (event){
                this.addFDPFields(event);
                this.$set(this.addForm, 'final_disposition_planning', {id: 0, name: 'Not Planned'});
                this.$set(this.addForm, 'fdp_status', { id: 0, name: 'No' });
                this.performAction(this.addForm, 'clearFDP', event);
            }
        },
        shallChangeFDP(event){
            if (event){
                this.addFDPFields(event);
                this.setDefaultValues();
            }
        },
        setField(field, value=null){
            this.addForm[field] = value;
            this.addForm = Object.assign({}, this.addForm);
        },
        updateLocationsList(option) {
            let locations_list = this.$root.form_lists.locations_list;
            locations_list = locations_list.filter(function (e) {
                return e.id === -99 || (e.parent_address ? e.parent_address.id === option.id : false);
            });
            this.addForm.location_id = locations_list.find(obj => {
                return obj.is_primary === 1;
            });
            if (this.$refs['LocationDispositionField'] && this.$refs['LocationDispositionField'].length > 0){
                this.$refs['LocationDispositionField'][0].updateLocationsList(locations_list);
            }
            this.$set(this.disposition_field_options, 'location_id', locations_list);
        },
        setDefaultValues() {
            if (this.required_fields.hasOwnProperty('final_disposition_planning')){
                let final_disposition_planning = this.my_asset.final_disposition_planning ? this.my_asset.final_disposition_planning : 0;
                let fdp_property_value = this.getObjectOf(final_disposition_planning, this.required_fields.final_disposition_planning.options);
                if (fdp_property_value && fdp_property_value.id === 2){
                    // Long Term Storage
                    this.has_assignments = true;
                }
                this.$set(this.addForm, 'final_disposition_planning', fdp_property_value);
            }


            if (this.required_fields.hasOwnProperty('fdp_status')){
                let fdp_status_property_value = this.getObjectOf(this.my_asset.fdp_status, this.required_fields.fdp_status.options);
                this.$set(this.addForm, 'fdp_status', fdp_status_property_value);
            }

        },
        changedRadioValue(field_key, option, event) {
            if (event){
                if (field_key === 'final_disposition_planning'){
                    this.has_assignments = false;
                    // shall reveal conditional content?
                    // Do nothing
                    if (option.id === 2){
                        // Long Term Storage
                        this.has_assignments = true;
                    }
                // }else if (field_key === 'fdp_status' && option.id === 1){
                //     this.performAction(this.addForm, 'proposeFDP', event);
                }else{
                    this.changedRadioValueOf(field_key, option.id);
                }
            }
        },
        shallRegisterDisposition(event){
            window.flash('', 'info', 'added_disposition');
            window.location.href = '/' + this.item_type + '/assets/' + this.my_asset.id + '/edit';
        }
    }
}
</script>
