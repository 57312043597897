<template>
    <div class="LabelRightBox mt-1">

        <div class="row">
            <div class="col-md-12">
                <h2 class="configure-label">Configure Your Label</h2>
                <p class="label-description">These settings update the preview and what is included in your label.</p>


                <div class="label_block label_type_block">
                    <div class="label_option_row">
                        <h4 class="label_option_label label_type_label">Label Type</h4>
                        <b-form-radio-group v-model="labelAddForm.type" class="label_options label_type_options">
                            <b-form-radio value="pdf" class="custom-radio custom-radio-af-accent"> PDF</b-form-radio>
                        </b-form-radio-group>
                    </div>
                </div>


                <div class="label_block studio_production_block">
                    <h3 class="heading_label_option studio_production_label">{{ is_tv_film_asset ? 'Studio & Production' : 'Group & Customer' }}</h3>

                    <div class="label_option_row">
                        <h4 class="label_option_label studio_label">{{ is_tv_film_asset ? 'Studio' : 'Group' }}</h4>
                        <b-form-radio-group v-model="labelAddForm.with_entity_group_logo" @change="changedFormValue('with_entity_group_logo')" class="label_options studio_options">
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                            <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                        </b-form-radio-group>
                    </div>

                    <div class="label_option_row">
                        <h4 class="label_option_label studio_label">{{ is_tv_film_asset ? 'Production' : 'Customer' }}</h4>
                        <b-form-radio-group v-model="labelAddForm.with_entity_logo" @change="changedFormValue('with_entity_logo')" class="label_options production_options">
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                            <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                        </b-form-radio-group>
                    </div>
                    <div class="label_option_row">
                        <h4 class="label_option_label with_department_label">Department</h4>
                        <b-form-radio-group v-model="labelAddForm.with_department" @change="changedFormValue('with_department')" class="label_options with_department_options">
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                            <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                        </b-form-radio-group>
                    </div>

                    <div class="label_option_row">
                        <h4 class="label_option_label with_wrap_box_label">{{ is_tv_film_asset ? 'Wrap Box' : 'Asset Group' }}</h4>
                        <b-form-radio-group v-model="labelAddForm.with_wrap_box" @change="changedFormValue('with_wrap_box')" class="label_options with_wrap_box_options">
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                            <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                        </b-form-radio-group>
                    </div>

                    <div class="label_option_row">
                        <h4 class="label_option_label with_date_label">Label Date</h4>
                        <b-form-radio-group v-model="labelAddForm.with_date" @change="changedFormValue('with_date')" class="label_options with_date_options">
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                            <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                        </b-form-radio-group>
                    </div>

                </div>


                <div class="label_block images_block">
                    <h3 class="heading_label_option images_label">Images</h3>

                    <div class="label_option_row">
                        <h4 class="label_option_label first_image_label">First Image</h4>
                        <b-form-radio-group v-model="labelAddForm.first_image" @change="changedFormValue('first_image')" class="label_options first_image_options">
                            <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> Featured Image</b-form-radio>
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> First Image</b-form-radio>
                            <b-form-radio :value="2" class="custom-radio custom-radio-af-accent"> Last Image</b-form-radio>
                        </b-form-radio-group>
                    </div>

                    <div class="label_option_row">
                        <h4 class="label_option_label all_images_label">All Images</h4>
                        <b-form-radio-group v-model="labelAddForm.all_images" @change="changedFormValue('all_images')" class="label_options all_images_options">
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                            <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                        </b-form-radio-group>
                    </div>
                </div>


                <div class="label_block sections_block">
                    <h3 class="heading_label_option sections_label">Sections</h3>


                    <div class="label_option_row">
                        <h4 class="label_option_label asset_identification_label">Identification</h4>
                        <b-form-radio-group v-model="labelAddForm.asset_identification" @change="changedFormValue('asset_identification')" class="label_options asset_identification_options">
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                            <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                        </b-form-radio-group>
                    </div>


                    <div class="label_option_row">
                        <h4 class="label_option_label with_usage_label">Usage</h4>
                        <b-form-radio-group v-model="labelAddForm.with_usage" @change="changedFormValue('with_usage')" class="label_options with_usage_options">
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                            <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                        </b-form-radio-group>
                    </div>


                    <div class="label_option_row">
                        <h4 class="label_option_label asset_details_label">Details</h4>
                        <b-form-radio-group v-model="labelAddForm.asset_details" @change="changedFormValue('asset_details')" class="label_options asset_details_options">
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                            <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                        </b-form-radio-group>
                    </div>

                    <div class="label_option_row">
                        <h4 class="label_option_label with_tags_label">Tags</h4>
                        <b-form-radio-group v-model="labelAddForm.with_tags" @change="changedFormValue('with_tags')" class="label_options with_tags_options">
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                            <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                        </b-form-radio-group>
                    </div>

                </div>


                <div class="label_block label_creation_block">
                    <h3 class="heading_label_option label_creation_label">After Label Creation</h3>
                    <div class="label_option_row">
                        <h4 class="label_option_label where_to_label">Where To</h4>
                        <b-form-radio-group v-model="labelAddForm.where_to" @change="changedFormValue('where_to')" class="label_options where_to_options">
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Stay Here</b-form-radio>
                            <b-form-radio :value="6" class="custom-radio custom-radio-af-accent"> File Object</b-form-radio>
                        </b-form-radio-group>
                    </div>


                    <div class="label_option_row">
                        <h4 class="label_option_label send_email_label">Email Link</h4>
                        <b-form-radio-group v-model="labelAddForm.send_email" @change="changedFormValue('send_email')" class="label_options send_email_options">
                            <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                            <b-form-radio :value="2" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                        </b-form-radio-group>
                    </div>
                </div>


                <div class="settings_action_btn">
                    <button class="btn btn-primary af-accent btn-sm btn-block" @click="performAction(my_asset, 'exportAsset', $event, labelAddForm)">GENERATE</button>
                </div>

                <div class="label_file_block" v-if="my_asset.hasOwnProperty('bulk_update_record_id') && my_asset.bulk_update_record_id">
                    <p class="label-file-fired" v-if="!my_asset.hasOwnProperty('export_file_id')">Label will be available here once generated. <a href="javascript:void(0);" @click="getBulkUpdateInfo(my_asset.bulk_update_record_id, my_asset)">Click to Refresh</a>.</p>
                    <p class="label-file-being-created" v-else-if="my_asset.export_file_id === null">Label is being created. <a href="javascript:void(0);" @click="getBulkUpdateInfo(my_asset.bulk_update_record_id, my_asset)">Click to Refresh</a>.</p>
                    <div class="ready-label-block" v-else-if="my_asset.export_file_id !== null && my_asset.hasOwnProperty('export_file_info') && my_asset.export_file_info">
                        <p class="ready-label-file">Label is ready.</p>
                        <div class="row label-file-block">
                            <div class="col-lg-12">
                                <po-lines
                                :items="[my_asset.export_file_info]"
                                :current_page_type="item_type"
                                object_type="files"
                                :keys_list="file_short_keys_list"
                                custom_header="File Details"
                                >
                                    <template v-slot:files_download="{ item, index }">
                                        <div class="button-items" v-if="item.status !== 2">
                                            <b-button variant="light" size="sm" :href="item.file_url" target="_blank"><i class="fa fa-eye"></i></b-button>
                                            <b-button variant="light" size="sm" :href="item.file_url_download" target="_blank"><i class="fa fa-download"></i></b-button>
                                        </div>
                                    </template>
                                </po-lines>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import PoLines from "@/views/assetflow/items/widgets/detailsViews/PoLines";

export default {
    components: {PoLines},
    name: 'LabelRightBox',
    props: {
        item_type: {},
        my_asset: {},
        performAction: {},
        labelAddForm: {},
        changedFormValue:{},
        getBulkUpdateInfo:{},
        file_short_keys_list:{},
    },
}
</script>

<style scoped>
.label_option_row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.label_option_label {
    margin-right: 20px;
}

.heading_label_option {
    margin-top: 10px;
}

</style>
