<template>
    <div class="intelligence-template">
        <div class="mt-1">
            <a :href="'/' + item_info.current_page_type + '/' + item_info.item_type" class="btn btn-primary btn-sm af-accent" style="width:inherit;">
                <i class="fas fa-arrow-left"></i> Bulk Updates List
            </a>
        </div>

        <div class="tab_menu_item">
            <ul class="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                <li v-for="tab in tabs" class="nav-item">
                    <a class="nav-link"
                       @click="tabClicked(tab.id)"
                       :class="isActiveTab(tab.id)"
                       data-toggle="tab" :href="'#'+tab.id" role="tab">{{ tab.name }}</a>
                </li>
            </ul>
        </div>
        <div class="tab-content tab_content_design">
            <div class="tab-pane" id="bur_overview_tab" role="tabpanel" :class="isActiveTab('bur_overview_tab')">
                <h4>Overview</h4>
                <BulkActionOverview :item_info="item_info"/>
            </div>
            <div class="tab-pane" id="bur_line_items_tab" role="tabpanel" :class="isActiveTab('bur_line_items_tab')">
                <h4>Line Items</h4>
                <BulkChildRecordsTab :item_info="item_info" page_unique_name="change_files" :object_type="'child_records'"/>
            </div>
        </div>

        <div class="row tab_content_design refresh-button">
            <div class="col-12">
                <button class="btn btn-primary af-accent af-refresh-btn" @click="reloadPage(null)">Refresh</button>
                <a v-if="item_info.hasOwnProperty('action_object') && item_info.action_object && item_info.action_object.hasOwnProperty('url') && item_info.action_object.url"
                   class="btn btn-primary af-accent af-refresh-btn" :href="item_info.action_object.url" target="_blank">Go To {{item_info.action_object.name }}</a>

            </div>
        </div>


    </div>
</template>
<script>
import PoLines from "./widgets/detailsViews/PoLines";
import BulkActionOverview from "./BulkActionOverview";
import BulkChildRecordsTab from "./BulkChildRecordsTab";

export default {
    name: 'CustomBulkUpdateDetails',
    components: {BulkChildRecordsTab, BulkActionOverview, PoLines},
    props: ['item_info'],
    data(){
        return {
            active_tab: 'bur_overview_tab',
            tabs: {
                'bur_overview_tab': {
                    'id': "bur_overview_tab",
                    'name': "Overview",
                    'icon': "user",
                    'type': "bur_overview_tab",
                    'isActive': false
                },
                'bur_line_items_tab': {
                    'id': "bur_line_items_tab",
                    'name': "Line Items",
                    'icon': "user",
                    'type': "bur_line_items_tab",
                    'isActive': false
                },
            },
            child_records_key_list:{}
        }
    },
    created() {
        let bulk_updates_active_tab = localStorage.getItem('bulk_updates_active_tab');
        let queryVars = this.getQueryVars();

        if (queryVars.hasOwnProperty('t') && queryVars.t !== ''){
            let selected_tab = queryVars.t;
            switch (selected_tab){
                case 'overview':
                    this.active_tab = 'bur_overview_tab';
                    break;
                case 'line_items':
                    this.active_tab = 'bur_line_items_tab';
                    break;
            }
        }else if (bulk_updates_active_tab && bulk_updates_active_tab !== ''){
            this.active_tab = bulk_updates_active_tab;
        }
    },
    methods:{
        tabClicked(tab_id){
            localStorage.setItem('bulk_updates_active_tab', tab_id);
        },
        isActiveTab(tab_id){
            return this.active_tab === tab_id ? 'active' : '';
        },
    }
}
</script>

